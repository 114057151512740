<template>
  <div class="vacancy-folders">
    <div class="candidates-on-probation" v-if="candidatesOnProbation.length">
      <div class="candidates-on-probation-list">
        <router-link
          class="candidates-on-probation_item" v-for="(candidate, index) in candidatesOnProbation" :key="index"
          :to="`/candidates/candidate/${candidate.candidate.id}/`"
        >
          <CandidateInfoBlock :item="candidate"/>
          <CandidateFolder :candidate="candidate"/>
        </router-link>
      </div>
    </div>
    <div class="candidates-in-work" v-if="candidatesInWork.length">
      <div class="candidates-in-work-list">
        <router-link class="candidates-in-work_item" v-for="(candidate, index) in candidatesInWork" :key="index"
                     :to="`/candidates/candidate/${candidate.candidate.id}/`"
        >
          <CandidateInfoBlock :item="candidate"/>
          <CandidateFolder :candidate="candidate"/>
        </router-link>
      </div>
    </div>
    <div class="vacancy-folders_header">

      <div class="vacancy-folders_title" @click="goToFolder('all')">
        <span>Кандидатов: {{ vacancy.candidates ? vacancy.candidates.length : '0' }}</span>
        <span class="icon icon-arrow flip-horizontally"></span>
      </div>

      <div
        v-if="vacancy.access_type !== 'read'"
        class="add-candidate-button transparent-button"
        @click="addCandidatesModalOpen">
        <div class="icon icon-new"></div>
        <span>Добавить кандидата</span>
      </div>
    </div>
    <div
      v-show="vacancy.access_type !== 'read' && (vacancy.candidates && !vacancy.candidates.length)"
      class="funnel-settings">
      <v-row class="mt-4">
        <v-col cols="8">
          <v-select
            v-if="funnels.length > 1"
            :items="funnels"
            return-object
            placeholder="Не выбрана"
            persistent-placeholder
            append-icon=""
            label="Выбрать из предыдущих воронок"
            v-model="funnel"
            hide-details="auto"
            @change="canEditFunnel = true"
          >
            <template v-slot:item="{item}">
              <div style="display: flex; flex-wrap: wrap; max-width: 570px;">
                <span
                  class="mr-1 py-1"
                  style="white-space: nowrap; min-width: 100px;"
                  v-for="(_item, index) in item" :key="index">{{ _item.title }}{{ index === item.length - 1 ? '' : ',' }}</span>
              </div>
            </template>
            <template v-slot:selection="{parent, item}">
              <div style="display: flex; flex-wrap: wrap; max-width: 550px">
                <span
                  class="mr-1"
                  style="white-space: nowrap;"
                  v-for="(_item, index) in item" :key="index">{{ _item.title }}{{ index === item.length - 1 ? '' : ',' }}</span>
              </div>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </div>
    <div class="vacancy-folders_list">
      <ul>
        <v-skeleton-loader v-if="vacancyLoading" type="list-item" height="20" class="py-6"/>
        <draggable v-else-if="vacancy.funnel && vacancy.funnel.length " item-key="id" v-model="vacancy.funnel" :options="{disabled : !canEditFunnel}">
          <li v-for="(step, key) in vacancy.funnel" :key="key">
            <router-link
              :class="{'vacancy-folders_item': true,
            'active': vacancy.candidates.filter((candidate)=>+candidate.folder === key).length}"
              :to="isShared ? {name: 'VacancyFolder', params: {vacancyId: vacancy.id, folder: key, token: $route.params.token} } : !canEditFunnel ? {name: 'VacancyFolder',
            params: {vacancyId: vacancy.id, folder:key}} : ''"
            >
              <div class="link-chain"></div>
              <div class="vacancy-folders_item_inner">
                <div class="vacancy-folders_item_title">
                  <v-text-field
                    v-if="canEditFunnel"
                    placeholder="Название"
                    v-model="step.title"
                    hide-details
                  />
                  <span v-else>{{ step.title }}</span>
                </div>
                <div v-if="canEditFunnel" class="hidden-checkbox-wrap">
                  <v-checkbox
                    v-model="step.hidden"
                    true-value="1"
                    false-value="0"
                    label="Скрыть для других"
                    hide-details
                  />
                </div>
                <div
                  @click.prevent="$refs['confirm-delete-step'].open({stepIndex: key})"
                  v-if="canEditFunnel"
                  class="vacancy-folders_item_edit">
                  <v-icon size="18" color="primary">mdi-trash-can-outline</v-icon>
                </div>
                <div :class="{'vacancy-folders_item_after': true, 'empty': !vacancy.candidates.filter((candidate)=>+candidate.folder === key).length}">
                  <div class="vacancy-folders_item_count">
                    {{ vacancy.candidates.filter((candidate) => +candidate.folder === key && !+candidate.not_suitable).length }}
                    <span style="color: rgba(var(--page-color-main-rgb), 0.25)" class="ml-1">
                    ({{ vacancy.candidates.filter((candidate) => +candidate.folder === key).length }})
                  </span>
                  </div>
                  <div class="icon icon-arrow flip-horizontally"></div>
                </div>
              </div>
            </router-link>
          </li>
        </draggable>
        <NoResults v-else>
          <template #text>Воронки нет. Вы можете добавить этапы вручную</template>
        </NoResults>
      </ul>
      <router-link v-if="vacancy.candidates && vacancy.candidates.filter((candidate) => +candidate.not_suitable === 1).length"
                   class="vacancy-folders_item active"
                   :to="{name: 'VacancyFolder', params: {vacancyId: vacancy.id, folder:'not_suitable' } }">
        <div class="vacancy-folders_item_inner">
          <div class="vacancy-folders_item_title red-bg not-suit">
            <span>Не подходит</span>
          </div>
          <div class="vacancy-folders_item_after">
            <div class="vacancy-folders_item_count">
              {{ vacancy.candidates.filter((candidate) => +candidate.not_suitable === 1).length }}
            </div>
            <div class="icon icon-arrow flip-horizontally"></div>
          </div>
        </div>
      </router-link>
    </div>

    <div class="vacancy-folders_actions" v-if="vacancy.access_type !== 'read'">
      <v-btn
        v-if="vacancy.funnel && vacancy.funnel.length"
        text color="text_button" class="mr-3 shadow-none" @click="canEditFunnel = !canEditFunnel">
        {{ canEditFunnel ? 'Назад' : 'Редактировать' }}
      </v-btn>
      <v-btn
        text
        color="text_button"
        class="mr-3"
        v-if="canEditFunnel" @click="updateFunnel">Сохранить
      </v-btn>
      <v-btn v-if="canEditFunnel" text color="text_button" @click="$refs.edit_folder_modal.open(vacancy)">
        <div class="icon icon-new mr-1"></div>
        Добавить
      </v-btn>
    </div>
    <EditFolderModal ref="edit_folder_modal"/>
    <Confirm ref="confirm-replace-candidates" cancel-text="Отмена" accept-text="Да"
             message="В этой папке находятся кандидаты. Переместить их?" @accept="replaceCandidates"/>
    <Confirm ref="confirm-delete-step" cancel-text="Отмена" accept-text="Да"
             message="Удалить эту папку?" @accept="deleteStep"/>
  </div>
</template>

<script>
import EditFolderModal from '@/views/vacancy/EditFolderModal.vue';
import ConflictVacancyModal from '@/views/vacancy/ConflictVacancyModal.vue';
import draggable from 'vuedraggable';
import NoResults from '@/components/NoResults';

export default {
  name: 'VacancyFoldersList',
  components: {
    NoResults,
    EditFolderModal,
    ConflictVacancyModal,
    draggable
  },
  props: {
    vacancy: Object,
    vacancyLoading: {
      type: Boolean,
      default: false
    },
    funnels: {
      type: Array,
      default: ()=>[],
    },
    defaultFunnel: {
      type: Array,
      default: ()=>[],
    }
  },
  data() {
    return {
      isShared: this.$route.path.split('/').includes('shr'),
      canEditFunnel: false,
      currentDeletingStepIndex: null,
      funnel: [],
    };
  },
  emits: ['add-candidates'],
  methods: {
    addCandidates(ids) {
      this.$server.request2(`vacancy/addCandidates/${this.vacancy.id}`, { ids }, (data) => {
        if (!data.errors.length) {
          this.$eventBus.emit('load-vacancy');
          return;
        }

        const conflicts = data.errors.map(([candidateId, currentVacancy]) => {
          return {
            newVacancy: this.vacancy,
            currentVacancy,
            candidateInfo: currentVacancy.candidates.find((candidate) => candidate.id === candidateId),
            selectedCandidate: 0,
          };
        });
        this.$eventBus.emit('open-conflict-modal', { conflicts, vacancyId: this.vacancy.id })
      });
    },
    updateFunnel() {
      this.$server.request(`vacancy/update/${this.vacancy.id}`, { funnel: this.vacancy.funnel })
        .then(() => {
          this.canEditFunnel = false;
          this.$eventBus.emit('load-vacancy');
        });
    },
    deleteStep(stepIndex) {
      this.currentDeletingStepIndex = stepIndex;
      let vacancy = this.vacancy;
      if (vacancy.candidates.filter((candidate) => +candidate.folder === +stepIndex && !+candidate.not_suitable).length > 0) {
        this.$refs['confirm-replace-candidates'].open();
        return;
      }
      if (stepIndex < this.vacancy.funnel.length - 1) {
        for (let i = 1; i <= this.vacancy.funnel.length - 1; i++) {
          this.$server.request2('candidate/changeFolder', {
            vacancy: this.vacancy.id,
            candidates: this.vacancy.candidates.filter((candidate) => +candidate.folder === stepIndex + i)
              .map((_candidate) => _candidate.candidate.id),
            folder: stepIndex + i - 1,
          });
        }
      }
      vacancy.funnel.splice(stepIndex, 1);
      this.$server.request2(`vacancy/update/${vacancy.id}`, { funnel: vacancy.funnel })
        .then(() => {
          this.$eventBus.emit('load-vacancy');
        });
    },
    goToFolder(folderIndex) {
      if (this.isShared) {
        this.$router.push(`/shr/v/folder/${folderIndex}/${this.$route.params.token}/${this.vacancy.id}`);
      } else {
        this.$router.push(`/vacancies/vacancy/${this.vacancy.id}/folder/${folderIndex}`);
      }
    },
    replaceCandidates() {
      this.$eventBus.emit('change-folder-candidates-modal-open', {
        candidateIds: this.vacancy.candidates.filter((candidate) => +candidate.folder === this.currentDeletingStepIndex)
          .map((item) => item.candidate.id),
        vacancyId: this.vacancy.id,
      });
      this.currentDeletingStepIndex = null;
      this.$refs['confirm-replace-candidates'].close();
    },
    addCandidatesModalOpen(params = {}) {
      this.$eventBus.emit('add-candidates-modal-open', {
        ...params,
        vacancyId: this.vacancy.id,
        multiple: true,
        needLoad: true,
        alreadyExistsCandidates: this.vacancy.candidates.map((candidate) => candidate.candidate.id),
        from: 'folder_list'
      });
    }
  },
  created() {
    this.$eventBus.on('candidates-selected-folder_list', (ids) => {
      this.addCandidates(ids);
    });
    this.$eventBus.on('vacancy-create', () => this.canEditFunnel = true);
    this.$eventBus.on('create-from-vacancy-add-candidate', () => {
      this.addCandidatesModalOpen({ getCheckedCandidatesFromLS: true });
    });

  },
  destroyed() {
    this.$eventBus.off('candidates-selected-folder_list');
    this.$eventBus.off('vacancy-create');
  },
  beforeDestroy() {
    this.$eventBus.off('candidates-selected-folder_list');
    this.$eventBus.off('vacancy-create');
  },
  computed: {
    candidatesOnProbation() {
      return this.vacancy.candidates ? this.vacancy.candidates.filter((candidate) => +candidate.date_notify === 1) : [];
    },
    candidatesInWork() {
      return this.vacancy.candidates ? this.vacancy.candidates.filter((candidate) => +candidate.date_work === 1) : [];
    },
  },
  watch: {
    funnel(newVal) {
      if ( !newVal.length || newVal[0].title !== 'Оставить воронку по умолчанию') {
        this.vacancy.funnel = newVal;
      } else {
        this.vacancy.funnel = this.defaultFunnel;
      }
    }
  }
};
</script>

<style lang="scss">

.vacancy-folders {
  &_header {
    padding: 28px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-color);

    .add-candidate-button {
      width: auto;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      .icon {
        margin-right: 6px;
        font-size: 11px;
        font-weight: 600;
      }
    }
  }

  &_title {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -1px;
    color: var(--page-color-main) !important;
    cursor: pointer;

    .icon {
      margin-left: 8px;
      font-size: 16px;
      color: rgba(var(--page-color-main-rgb), .25);
    }
  }

  .funnel-settings-title {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -1px;
  }

  &_list {
    margin-bottom: 20px;

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .vacancy-folders_item_inner {
      .v-input {
        margin-top: 0;
        padding-top: 0;
      }

      .v-input--checkbox .v-label {
        font-size: 12px;
      }
    }

    .vacancy-folders_item_title {
      &.not-suit {
        width: fit-content;
        padding: 4px 12px;
        border-radius: 4px;
        flex-basis: auto;
      }

      .v-input__slot::before {
        display: none;
      }

      .v-input input {
        border: none;
        height: 20px;
      }
    }

    li:first-child {
      .vacancy-folders_item {
        .link-chain:after {
          content: none;
        }
      }
    }
  }

  .candidates-on-probation, .candidates-in-work {
    &_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid var(--border-color);
    }
  }

  &_item {
    position: relative;
    color: rgba(var(--page-color-main-rgb), .25) !important;
    cursor: pointer;
    display: block;

    .link-chain {
      width: 42px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;

      &:before {
        content: '';
        display: block;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: rgba(var(--page-color-main-rgb), .15);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:after {
        content: '';
        display: block;
        height: calc(100% - 22px);
        width: 2px;
        background: rgba(var(--page-color-main-rgb), .15);
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &_inner {
      margin-left: 42px;
      padding: 24px 0;
      border-bottom: 1px solid var(--border-color);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .hidden-checkbox-wrap {
        display: flex;
        font-size: 13px;

        .checkbox {
          margin-right: 10px;
        }
      }
    }

    &_title {
      font-size: 14px;
      line-height: 20px;
      flex-basis: 50%;
      margin-right: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        display: block;
        max-width: 350px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &_edit {
      justify-content: flex-end;
      flex-basis: 7%;
      display: flex;

      .icon {
        margin-left: 10px;
      }
    }

    &_after {
      display: flex;
      align-items: center;
      flex-basis: 20%;
      justify-content: flex-end;

      .icon {
        margin-left: 6px;
        font-size: 14px;
      }

      &.empty {
        color: rgba(var(--page-color-main-rgb), .25);
      }
    }

    &_count {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    &.active {
      color: var(--page-color-main) !important;

      .link-chain {
        &:before, &:after {
          background: var(--page-color-main);
        }
      }
    }
  }

  &_actions {
    display: flex;

    .cancel-button {
      margin-right: 20px;
    }

    .save-button {
      margin-right: 20px;
    }
  }

}
</style>
