<template>
  <Modal ref="modal" class="customers-list-modal" @backdrop-click="close" @close-icon-click="close" title="Добавить заказчика">
    <div class="customers-list">
      <ul v-if="customers.length > 0">
        <li v-for="customer in customers" :key="customer.id">
          <label class="customers-list-item">
            <div class="customers-list-item_inner">
              <div class="customers-list-item_title">{{ customer.name }}</div>
              <div class="customers-list-item_subtitle">{{ customer.email }}</div>
            </div>
            <div class="customers-list-item_after">
              <div class="check-wrap">
                <input
                  :type="'radio'"
                  name="customers"
                  :checked="checkedCustomerIds.includes(customer.id)"
                  @change="changeCustomer($event, customer)"
                >
                <div class="icon icon-check"></div>
              </div>
            </div>
          </label>
        </li>
      </ul>
      <NoResults
        v-else
      >
        <template v-slot:title>Заказчиков пока нет</template>
      </NoResults>
    </div>
    <div class="add-customer-button transparent-button" @click="formNewCustomerVisible = !formNewCustomerVisible">
      <div class="icon icon-new"></div>
      <span>Новый заказчик</span>
    </div>
    <v-form class="form-add-customer" v-if="formNewCustomerVisible" ref="form_add_customer">
      <v-text-field
        v-model="newCustomer.name"
        placeholder="Имя"
        :rules="[v=>!!v || newCustomerNameError]"
      />
      <v-text-field
        v-model="newCustomer.email"
        placeholder="E-mail"
        :rules="[v=>!!v || newCustomerEmailError]"
      />
      <div class="white-button" @click="addCustomer(newCustomer)">Добавить</div>
    </v-form>
    <div class="modal-actions">
      <v-btn color="primary" depressed :ripple="false" :disabled="!checkedCustomerIds.length">Готово</v-btn>
    </div>
  </Modal>
</template>

<script>
export default {
  name: 'CustomersListModal',
  props: {
    multiple: Boolean,
    requestParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      customers: [],
      vacancy_id: null,
      selectedCustomer_id: null,
      checkedCustomerIds: [],
      newCustomer: {},
      selectedCustomer: {},
      formNewCustomerVisible: false,
      newCustomerNameError: '',
      newCustomerEmailError: '',
    };
  },
  methods: {
    open(data) {
      this.vacancy_id = data.vacancy_id;
      this.$refs.modal.open();
      this.selectedCustomer_id = data.selectedCustomer_id;
      this.getCustomers();
    },
    close() {
      this.newCustomer = {};
      this.newCustomerNameError = '';
      this.newCustomerEmailError = '';
      this.formNewCustomerVisible = false;
      this.$refs.modal.close();
    },
    getCustomers() {
      this.$server.request2('customer/get', {}, (data) => {
        this.customers = data.response;
        this.selectedCustomer = data.response.find((customer) => customer.id === this.selectedCustomer_id);
        this.checkedCustomerIds.push(this.selectedCustomer_id);
      });
    },
    submit() {
      if (this.checkedCustomerIds.length) {
        this.$emit('submit', { customer: this.checkedCustomerIds[0] });
        this.customers = [];
        this.checkedCustomerIds = [];
        this.close();
      }
    },
    changeCustomer(event, customer) {
      if (event.target.checked) {
        this.selectedCustomer = customer;
        this.checkedCustomerIds[0] = customer.id;
      } else {
        this.checkedCustomerIds = this.checkedCustomerIds.filter((id) => id !== customer.id);
      }
      console.log(this.checkedCustomerIds);
    },
    addCustomer(customer) {
      if (this.$refs.form_add_customer.validate()) {
        if (!this.$store.state.user.name) {
          this.$eventBus.emit('open-edit-user-modal');
          return;
        }
        this.$server.request2(`vacancy/update/${this.vacancy_id}`, { customer }, () => {
          this.getCustomers();
          this.formNewCustomerVisible = false;
          this.newCustomer = {};
          this.close();
        });
      }
    },
  },
};
</script>

<style lang="scss">
.customers-list-modal {
  .modal-content {
    top: 30px !important;

    .customers-list {
      min-height: 185px;
      margin-top: 18px;
      margin-bottom: 28px;
      max-height: 300px;
      overflow: scroll;

      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
      }

      li {
        border-top: 1px solid var(--border-color);

        &:last-child {
          border-bottom: 1px solid var(--border-color);
        }
      }
    }

    .customers-list-item {
      cursor: pointer;
      display: flex;

      &_media {
        position: relative;
        width: 46px;
        height: 46px;
        padding: 12px 0;
        box-sizing: content-box;
        margin-right: 18px;
        flex-shrink: 0;

        .candidate-media {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .candidate-rating {
          position: absolute;
          bottom: 12px;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }

      &_inner {
        width: 100%;
        padding: 15px 0;

        & > * {
          margin-bottom: 4px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &_title {
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
      }

      &_subtitle {
        font-size: 14px;
        line-height: 16px;
        color: rgba(var(--page-color-main-rgb), .5);
      }

      &_after {
        align-self: stretch;
        display: flex;
        align-items: center;
      }

      .check-wrap {
        input {
          display: none;

          &:checked ~ .icon {
            display: block;
          }
        }

        .icon {
          display: none;
        }
      }
    }

    .add-customer-button {
      margin-bottom: 20px;
      font-size: 16px;

      .icon-new {
        margin-right: 8px;
      }
    }

    .form-add-customer {
      margin-bottom: 20px;

      .form-field {
        margin-bottom: 20px;
      }

      .form-field:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
