<template>
  <div class="vacancy-events">
    <div class="vacancy-events_header" v-if="vacancy.access_type !== 'read'">
      <v-btn text color="blue" depressed :ripple="false" @click="$eventBus.emit('create-event-modal-open', {vacancy})" class="mr-4 pa-0">
        <v-icon size="20" class="mr-1">mdi-plus</v-icon> Собеседование
      </v-btn>
      <v-btn text color="blue" depressed :ripple="false" @click="$eventBus.emit('create-event-modal-open', {type: '2', vacancy})" class="pa-0">
        <v-icon size="20" class="mr-1">mdi-plus</v-icon> Напоминание
      </v-btn>
    </div>
    <div class="vacancy-events_list">
      <EventList :events="events" v-if="events.length">
        <template #itemAfter="{ event }">

          <PopoverList :control-buttons="actionButtons" :item="event" v-if="vacancy.access_type !== 'read'"/>

<!--          <v-menu>-->
<!--            <template v-slot:activator="{attrs, on}">-->
<!--              <v-btn icon v-bind="attrs" v-on="on">-->
<!--                <v-icon>mdi-dots-horizontal</v-icon>-->
<!--              </v-btn>-->
<!--            </template>-->
<!--            <v-list>-->
<!--              <v-list-item v-for="(button, index) in actionButtons" :key="index">-->
<!--                <v-btn text :color="button.color" @click.stop="button.handler(event.id)">-->
<!--                  <v-icon v-if="button.icon" class="mr-2">{{button.icon}}</v-icon>-->
<!--                  {{button.text}}-->
<!--                </v-btn>-->
<!--              </v-list-item>-->
<!--            </v-list>-->
<!--          </v-menu>-->
        </template>
      </EventList>
      <NoResults v-else>
        <template #text>
          Событий нет
        </template>
      </NoResults>
    </div>
    <Confirm ref="confirm_remove_modal" message="Вы точно хотите удалить событие?" @accept="removeEvent"/>
  </div>
</template>

<script>
import EventList from '@/views/event/EventList.vue';
import NoResults from '@/components/NoResults';

export default {
  name: 'VacancyEventsList',
  components: { NoResults, EventList },
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    vacancyId: {
      type: String,
    },
    vacancy: {
      type: Object,
      default: {},
    }
  },
  data() {
    return {
      actionButtons: [
        {
          text: 'Изменить',
          icon: 'mdi-pencil',
          color: 'primary',
          handler: (itemsArr) => {
            itemsArr[0].type === '3' ?
            this.$eventBus.emit('not-main-event-modal-open', itemsArr[0].id)
              : this.$eventBus.emit('create-event-modal-open', {event_id: itemsArr[0].id})
          },
        },
        {
          text: 'Удалить',
          icon: 'mdi-delete-outline',
          color: 'red_color',
          handler: (itemsArr) => {
            this.$refs.confirm_remove_modal.open({vacancyIds: itemsArr}); /*vacancyIds - старое название. В модалке зачем то назвал относительно вакансии*/
          },
        },
      ]
    };
  },
  methods: {
    removeEvent(ids) {
      this.$server.request2(`event/remove/${ids[0].id}`, {}, () => {
        this.$eventBus.emit('load-vacancy');
      });
    }
  },
  created() {
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.add-event-button {
  width: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .icon {
    margin-right: 6px;
    font-size: 11px;
    font-weight: 600;
  }
}

.vacancy-events {
  &_header {
    margin-bottom: 40px;
    padding: 24px 0;
    border-bottom: 1px solid var(--border-color);
  }
}

.icon.icon-more {
  font-size: 24px;
  color: rgba(var(--page-color-main-rgb), .25);
  cursor: pointer;

  &:hover {
    color: rgba(var(--page-color-main-rgb), 1);
  }
}
</style>
