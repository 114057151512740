<template>
  <Modal ref="modal" classes="edit-folder-modal" title="Добавить этап">
    <v-form class="edit-folder-form" ref="edit_folder_form">
      <v-text-field
        v-model="folder.title"
        placeholder="Не указано"
        label="Название"
        hide-details="auto"
        :rules="[v=>!!v || titleError]"
      />
      <v-checkbox v-model="folder.hidden" label="Скрыть для других" hide-details true-value="1" false-value="0"/>
    </v-form>
    <template v-slot:actions>
      <v-btn :ripple="false" depressed color="primary" @click="update">Сохранить</v-btn>
    </template>
  </Modal>
</template>

<script>
export default {
  name: 'EditFolderModal',
  components: {},

  data() {
    return {
      folder: {},
      vacancy: {},
      titleError: 'Укажите название',
      funnel: [],
    };
  },
  methods: {
    open(vacancy) {
      this.vacancy = {};
      this.folder = {};
      this.funnel = [...vacancy.funnel];
      this.vacancy = vacancy;

      this.$refs.modal.open();
      setTimeout(()=>{
        this.$refs.edit_folder_form.reset();
      })
    },
    close() {
      this.$refs.modal.close();
    },
    update() {
      if (this.$refs.edit_folder_form.validate()) {
        this.funnel.push(this.folder);
        this.$server.request2(`vacancy/update/${this.vacancy.id}`, { funnel: this.funnel }, () => {
          this.$eventBus.emit('load-vacancy');
          this.close();
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.edit-folder-modal {
  .edit-folder-form {
    margin-bottom: 20px;
  }
  .hidden-checkbox-wrap {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    .checkbox {
      margin-right: 12px;
    }
  }
  .edit-folder-actions {
    .default-button {
      margin-bottom: 10px;
    }
  }
}
</style>
