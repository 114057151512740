<template>
  <div class="page half-width with-right-fixed-content page-vacancy firework-container">
    <div class="page-content">
      <div class="page-header">
        <div class="page-header_left">
          <div class="page-header_back-link" @click="$router.go(-1)">
            <div class="icon icon-arrow"></div>
            <span>Назад</span>
          </div>
          <v-skeleton-loader v-if="loadingVacancy" type="text" height="34" width="300"/>
          <div class="page-title">{{ vacancy.title }}</div>

          <div class="vacancy-header-info">
            <div v-if="Number(vacancy.fee)" class="vacancy-header-info_item">
              <div class="vacancy-header-info_item_title">Гонорар</div>
              <div class="vacancy-header-info_item_text">{{ sumSeparator(vacancy.fee) }} ₽</div>
            </div>
          </div>
          <div  class="vacancy-closed-info" v-if="vacancy.candidates">
            <div class="vacancy-progress-stages_status"
                 :class="$store.state.static.vacancyStatuses.find(status=>status.status === vacancy.status*1).color">
              {{ $store.state.static.vacancyStatuses.find(status => status.status === vacancy.status * 1).text }}
            </div>
            <div class="vacancy-selected-candidates mt-4" v-if="candidatesOnWork.length || candidatesOnProbation.length">
              <div class="vacancy-header-info_item_title">Подобранные кандидаты</div>
              <v-expansion-panels flat class="vacancy-pickedup-candidates-panel">
                <v-expansion-panel v-if="candidatesOnWork.length">
                  <v-expansion-panel-header class="px-0">
                    Вышедшие на работу
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <router-link class="selected-candidates_item mt-4" v-for="candidate in candidatesOnWork" :key="candidate.candidate.id"
                         :to="`/candidates/candidate/${candidate.candidate.id}`">
                      <div class="selected-candidates_item_media">
                        <CandidateMedia :candidate="candidate"/>
                      </div>
                      <div class="selected-candidates_item_title">
                        {{ candidate.candidate.name }} {{ candidate.candidate.surname }}
                      </div>
                    </router-link>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="candidatesOnProbation.length">
                  <v-expansion-panel-header class="px-0">
                    На испытательном сроке
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <router-link class="selected-candidates_item mt-4" v-for="candidate in candidatesOnProbation" :key="candidate.candidate.id"
                                 :to="`/candidates/candidate/${candidate.candidate.id}`">
                      <div class="selected-candidates_item_media">
                        <CandidateMedia :candidate="candidate"/>
                      </div>
                      <div class="selected-candidates_item_title">
                        {{ candidate.candidate.name }} {{ candidate.candidate.surname }}
                      </div>
                    </router-link>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <div class="comment" v-if="vacancy.comment">{{ vacancy.comment }}</div>
          </div>
        </div>
      </div>
      <v-tabs v-model="selectedTab" class="vacancy-tabs" :hide-slider="true">
        <v-tab :ripple="false">Кандидаты</v-tab>
        <v-tab :ripple="false">История</v-tab>
        <v-tab :ripple="false" v-if="vacancy.access_type !=='read'">Редактировать</v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedTab">
        <v-tab-item>
          <VacancyFoldersList :vacancy="vacancy" @add-candidates="loadVacancy" :vacancy-loading="loadingVacancy" :funnels="funnels" :default-funnel="defaultFunnel"/>
        </v-tab-item>
        <v-tab-item>
          <VacancyEventsList :events="vacancy.events" :vacancy-id="vacancy.id" :vacancy="vacancy"/>
        </v-tab-item>
        <v-tab-item v-if="vacancy.access_type !=='read'">
          <VacancyAboutList :vacancy="vacancy"/>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <RightContent :vacancy="vacancy" :vacancy-loading="loadingVacancy"/>
  </div>
</template>
<script>
import VacancyAboutList from '@/views/vacancy/VacancyAboutList.vue';
import VacancyEventsList from '@/views/vacancy/VacancyEventsList.vue';
import VacancyFoldersList from '@/views/vacancy/VacancyFoldersList.vue';
import RightContent from '@/views/vacancy/RightContent';

export default {
  components: {
    RightContent,
    VacancyAboutList,
    VacancyFoldersList,
    VacancyEventsList,
  },

  props: {
    folder: [String, Number],
  },
  data() {
    return {
      vacancy: { user: {} },
      selectedTab: 0,
      loadingVacancy: false,
      funnels: [],
      defaultFunnel: [],
      tabsName: ['candidates', 'history', 'edit'],
    };
  },
  methods: {
    onAddCandidates() {
      // this.loadCandidatesInfo();
    },
    loadVacancy() {
      this.loadingVacancy = true;
      this.$server.request2(`vacancy/get2/${this.vacancy.id || this.$route.params.vacancyId}`, {}, (data) => {
        this.vacancy = data.response;
        this.defaultFunnel = data.response.funnel;
        if (!data.response.candidates.length) this.getFunnels();
        this.$eventBus.emit('vacancy-loaded');
        this.loadingVacancy = false;
      }, () => {
        this.loadingVacancy = false;
      });
    },
    getFunnels() {
      this.$server.request2('vacancy/funnels', {}, (data) => {
        this.funnels = data.response;

        this.funnels.push([{title: 'Оставить воронку по умолчанию'}]);
      });
    },
    getVacancyByToken() {
      this.loadingVacancy = true;
      this.$server.request2('vacancy/getByToken', {at: this.$route.params.token, vacancy: this.$route.params.id}, (data)=>{
        this.vacancy = data.response;
        this.loadingVacancy = false;
      }, ()=>{
        this.loadingVacancy = false;
      });
    },
    setQueryTab(tabIndex) {
      if (!this.$route.path.split('/').includes('shr')) {
        this.$router.replace({
          path: `/vacancies/vacancy/${this.$route.params.vacancyId}`,
          query: {tab: this.tabsName[tabIndex]}}).catch(e=>{});
      } else {
        this.getVacancyByToken();
      }
    },

  },
  computed: {
    candidatesOnWork() {
      return this.vacancy.candidates.filter(candidate=>candidate.date_work);
    },
    candidatesOnProbation() {
      return this.vacancy.candidates.filter(candidate=>candidate.date_notify_start*1 || candidate.date_notify*1);
    },
  },
  watch: {
    selectedTab(newTab) {
      this.setQueryTab(newTab);
    }
  },
  mounted() {
    if (!this.$route.path.split('/').includes('shr')) this.loadVacancy();
    const queryTab = this.$route.query.tab;
    if (queryTab) {
      this.selectedTab = this.tabsName.indexOf(queryTab);
    } else {
      this.setQueryTab(this.selectedTab);
    }
  },
  created() {
    this.$root.$refs.vacancy_page = this;
    if (this.$route.path.includes('vacancy'))
      this.$eventBus.on('load-vacancy, event-created, event-update, update-candidate-in-folder, access-given', () => {
        if (this.$route.path.includes('vacancy')) {
          this.loadVacancy();
        }
      });
  },
  beforeDestroy() {
    this.$eventBus.off('load-vacancy, event-created, event-update, update-candidate-in-folder, access-given');
  },
};
</script>
<style lang="scss">
.page-vacancy {
  .vacancy-pickedup-candidates-panel {
    max-width: 350px;
  }
  .selected-candidates_item {
    display: flex;
    align-items: center;

    &_media {
      flex-shrink: 0;
      margin-right: 12px;
    }
    .candidate-media {
      width: 24px;
      height: 24px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    &_title {
      width: fit-content;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    &_close {
      cursor: pointer;
      padding: 9px 0 9px 9px;
      flex-shrink: 0;

      .icon {
        font-size: 15px;
        font-weight: 500;
        color: rgba(var(--page-color-main-rgb), .25);
      }
    }
  }
}

.vacancy-tabs {

}



</style>
