<template>
  <div class="vacancy-about">
    <AboutList :items="itemsWithHandlers"/>
    <div class="remove-button" @click="$refs['remove-vacancy-confirm'].open()">Удалить</div>
    <Confirm ref="remove-vacancy-confirm" message="Удалить вакансию?" @accept="removeVacancy"/>
    <ModalWithFields ref="edit-modal" @submit="updateVacancy"/>
    <CustomersListModal ref="customers_list_modal" @submit="updateVacancy"/>
  </div>
</template>

<script>
import AboutList from '@/components/AboutList.vue';
import CustomersListModal from '@/views/customers/ListModal.vue';
import ModalWithFields from '@/components/ModalWithFields';
export default {
  components: {
    AboutList,
    CustomersListModal,
    ModalWithFields
  },
  name: 'VacancyAboutList',
  props: {
    vacancy: Object,
  },
  data() {
    return {
      modalFields: [],
    };
  },
  methods: {
    updateVacancy(formData) {
      this.$server.request2(`vacancy/update/${this.vacancy.id}`, formData, () => {
        this.$eventBus.emit('load-vacancy');
      });
    },
    removeVacancy() {
      this.$server.request2(`vacancy/remove/${this.vacancy.id}`, {}, () => {
        this.$router.replace('/vacancies/');
      });
    },
  },
  computed: {
    items() {
      return [
        {
          media: '<div class="icon icon-tag"></div>',
          title: 'Заголовок',
          value: this.vacancy.title,
          emptyValue: 'Не указан',
          fields: [{
            title: 'Заголовок',
            name: 'title',
            placeholder: 'Не указан',
            value: this.vacancy.title,
          }],
        },
        {
          media: '<div class="icon icon-wine"></div>',
          title: 'Гонорар',
          value: Number(this.vacancy.fee) ? this.sumSeparator(Number(this.vacancy.fee)) : '',
          emptyValue: 'Не указан',
          fields: [{
            title: 'Гонорар',
            name: 'fee',
            placeholder: 'Не указан',
            value: this.vacancy.fee,
            modelRawValue: true,
            mask: this.$store.state.static.masksSumSeparator,
          }],
        },
        /*{
          media: '<div class="icon icon-briefcase"></div>',
          title: 'Заказчик',
          value: this.vacancy?.customer?.name,
          emptyValue: 'Не указан',
          onClick: () => {
            this.$refs.customers_list_modal.open({
              vacancy_id: this.$route.params.vacancyId,
              selectedCustomer_id: this.vacancy.customer.id,
            });
          },
        },*/
        {
          media: '<div class="icon icon-tag"></div>',
          title: 'Опыт',
          value: this.vacancy.experience && `${this.vacancy.experience} ${this.declOfNum(this.vacancy.experience, 'год,года,лет')}`,
          emptyValue: 'Любой',
          fields: [{
            title: 'Опыт (лет)',
            name: 'experience',
            placeholder: 'Не указан',
            value: this.vacancy.experience,
          }],
        },
        {
          media: '<div class="icon icon-money"></div>',
          title: 'Зарплата',
          value: (() => {
            const salaryFrom = Number(this.vacancy.salary_from);
            const salaryTo = Number(this.vacancy.salary_to);

            if (salaryFrom && salaryTo) {
              return `${this.sumSeparator(salaryFrom)} - ${this.sumSeparator(salaryTo)}`;
            }
            if (salaryFrom) {
              return `От ${this.sumSeparator(salaryFrom)}`;
            }
            if (salaryTo) {
              return `До ${this.sumSeparator(salaryTo)}`;
            }
            return '';
          })(),
          emptyValue: 'Не указана',
          fields: [
            {
              title: 'Зарплата от',
              name: 'salary_from',
              placeholder: 'Не указана',
              value: this.vacancy.salary_from,
              mask: this.$store.state.static.masksSumSeparator,
              modelRawValue: true,
            },
            {
              title: 'Зарплата до',
              name: 'salary_to',
              placeholder: 'Не указана',
              value: Number(this.vacancy.salary_to) || '',
              mask: this.$store.state.static.masksSumSeparator,
              modelRawValue: true,
            },
          ],
        },
        {
          media: '<div class="icon icon-profile"></div>',
          title: 'Пол',
          value: (() => {
            if (Number(this.vacancy.gender) === 1) return 'Мужской';
            if (Number(this.vacancy.gender) === 2) return 'Женский';
            return '';
          })(),
          emptyValue: 'Любой',
          fields: [{
            title: 'Пол',
            name: 'gender',
            value: this.vacancy.gender,
            type: 'select',
            values: '0:Любой;1:Мужской;2:Женский',
          }],
        },
        {
          media: '<div class="icon icon-profile"></div>',
          title: 'Возраст',
          value: (() => {
            const ageFrom = Number(this.vacancy.age_from);
            const ageTo = Number(this.vacancy.age_to);

            if (ageFrom && ageTo) {
              return `${ageFrom} - ${ageTo}`;
            }
            if (ageFrom) {
              return `От ${ageFrom}`;
            }
            if (ageTo) {
              return `До ${ageTo}`;
            }
            return '';
          })(),
          emptyValue: 'Любой',
          fields: [
            {
              title: 'Возраст от',
              name: 'age_from',
              placeholder: 'Не указан',
              value: Number(this.vacancy.age_from) || '',
            },
            {
              title: 'Возраст до',
              name: 'age_to',
              placeholder: 'Не указан',
              value: Number(this.vacancy.age_to) || '',
            },
          ],
        },
        {
          media: '<div class="icon icon-briefcase"></div>',
          title: 'Описание',
          value: this.vacancy.additional,
          emptyValue: 'Напишите, с чем предстоит работать, какие навыки и знания требуются, какой уровень необходим',
          inputArea: 'textarea',
          fields: [{
            title: 'Описание',
            name: 'additional',
            placeholder: 'Напишите, с чем предстоит работать, какие навыки и знания требуются, какой уровень необходим',
            value: this.vacancy.additional,
            type: 'textarea',
          }],
        },
        {
          media: '<div class="icon icon-calendar"></div>',
          title: 'Дата начала',
          value: this.formatDate(this.vacancy.date_from, '{dd}.{mm}.{yyyy}'),
          emptyValue: 'Не указана',
          fields: [{
            title: 'Дата начала',
            name: 'date_from',
            placeholder: 'Не указана',
            value: this.vacancy.date_from,
            type: 'date',
          }],
        },
        {
          media: '<div class="icon icon-calendar"></div>',
          title: 'Дата окончания',
          value: this.formatDate(this.vacancy.date_to, '{dd}.{mm}.{yyyy}'),
          emptyValue: 'Не указана',
          fields: [{
            title: 'Дата окончания',
            name: 'date_to',
            placeholder: 'Не указана',
            value: this.vacancy.date_to,
            type: 'date',
          }],
        },
      ];
    },
    itemsWithHandlers() {
      return Array.from(this.items, (item) => ({
        arrow: true,
        classes: 'pointer',
        onClick: () => {
          this.$forceUpdate();
          this.$refs['edit-modal'].open(item.fields);
        },
        ...item,
      }));
    },
  },
};
</script>

<style lang="scss">
.vacancy-about {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-list {
  width: 100%;
  margin-bottom: 30px;
}

.remove-button {
  width: auto;
}
</style>
